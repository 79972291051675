/*
==================================
Table of content 
==================================

1. variable
2. Bootstrap default css
3. utility
4. text
5. body
6. button
7. form element
8. loader
9. header
10. avatar
11. sidebar
12. card
13. swiper sliders
14. list 
15. footer
16. dark mode
17. filter
18. Full calendar
19. Logo
20. Tabs
21. Tags
22. Thank you
23. RTL

*/
/* template variables for standard action color should be removed from '../vendor/bootstrap-5/scss/variables' */
:root {
  --finwallapp-green: #00dfa3;
  --finwallapp-red: #f73563;
  --finwallapp-yellow: #ffbd17;
  --finwallapp-blue: #3c63e2;
  --finwallapp-rounded: 20px;
  --finwallapp-padding: 15px;
  --finwallapp-input-rounded: 10px;
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #57bdff;
  --finwallapp-theme-color-grad-2: #4f77fb;
  --finwallapp-theme-color-grad-3: #6528cf;
  /* color schemes */
  --finwallapp-theme-color: #6500d8;
  --finwallapp-theme-text: #ffffff;
  --finwallapp-theme-text-primary: #000000;
  --finwallapp-theme-text-secondary: #999999;
  --finwallapp-theme-text-secondary-light: #bbbbbb;
  --finwallapp-theme-bordercolor: rgba(0, 0, 0, 0.1);
  --finwallapp-header: transparent;
  --finwallapp-header-active: #ffffff;
  --finwallapp-footer: #ffffff;
  --finwallapp-sidebar: var(--finwallapp-theme-color);
  --finwallapp-card-color: #ffffff;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #eff6f9;
  --finwallapp-page-bg-3: #faf0fb;
  --finwallapp-page-text: #000000;
  --finwallapp-page-link: var(--finwallapp-theme-color);
}

.theme-indigo {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #8b9dff;
  --finwallapp-theme-color-grad-2: #5c00f1;
  --finwallapp-theme-color-grad-3: #68008e;
  /* color schemes */
  --finwallapp-theme-color: #6610f2;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f1eff9;
  --finwallapp-page-bg-3: #f6f0fb;
}

.theme-purple {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #e908d8;
  --finwallapp-theme-color-grad-2: #5a2ab3;
  --finwallapp-theme-color-grad-3: #300284;
  /* color schemes */
  --finwallapp-theme-color: #6f42c1;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fff5fc;
  --finwallapp-page-bg-3: #f6f0fb;
}

.theme-pink {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ff71c3;
  --finwallapp-theme-color-grad-2: #e80075;
  --finwallapp-theme-color-grad-3: #4d00da;
  /* color schemes */
  --finwallapp-theme-color: #d63384;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f4f8ff;
  --finwallapp-page-bg-3: #ffe9f7;
}

.theme-red {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffc699;
  --finwallapp-theme-color-grad-2: #ff5d83;
  --finwallapp-theme-color-grad-3: #dc006a;
  /* color schemes */
  --finwallapp-theme-color: #f73563;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fffbf2;
  --finwallapp-page-bg-3: #ffeeee;
}

.theme-orange {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffda6b;
  --finwallapp-theme-color-grad-2: #ff7c15;
  --finwallapp-theme-color-grad-3: #e80774;
  /* color schemes */
  --finwallapp-theme-color: #fd7e14;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fffaf4;
  --finwallapp-page-bg-3: #fff4eb;
}

.theme-yellow {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffdb6f;
  --finwallapp-theme-color-grad-2: #f0ac00;
  --finwallapp-theme-color-grad-3: #f03000;
  /* color schemes */
  --finwallapp-theme-color: #ffbd17;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f9f4ef;
  --finwallapp-page-bg-3: #fbf8f0;
}

.theme-green {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #bdffaa;
  --finwallapp-theme-color-grad-2: #04c5de;
  --finwallapp-theme-color-grad-3: #00a0f5;
  /* color schemes */
  --finwallapp-theme-color: #00dfa3;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #eff6f9;
  --finwallapp-page-bg-3: #e6f7f0;
}

.theme-teal {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #a2fffa;
  --finwallapp-theme-color-grad-2: #00a2ad;
  --finwallapp-theme-color-grad-3: #3f51b5;
  /* color schemes */
  --finwallapp-theme-color: #0ab2be;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fff7fd;
  --finwallapp-page-bg-3: #e8faf8;
}

.theme-cyan {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #74e5fb;
  --finwallapp-theme-color-grad-2: #258cd6;
  --finwallapp-theme-color-grad-3: #3d00ab;
  /* color schemes */
  --finwallapp-theme-color: #0dcaf0;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f1eff9;
  --finwallapp-page-bg-3: #f0f8fb;
}

@media screen and (max-width: 340px) {
  :root {
    --finwallapp-padding: 10px;
    --finwallapp-rounded: 12px;
  }
}

/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #3c63e2;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #f73563;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffbd17;
  --bs-green: #00dfa3;
  --bs-teal: #0ab2be;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #3c63e2;
  --bs-secondary: #6c757d;
  --bs-success: #00dfa3;
  --bs-info: #0dcaf0;
  --bs-warning: #ffbd17;
  --bs-danger: #f73563;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #3c63e2;
  text-decoration: underline;
}

a:hover {
  color: #304fb5;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  /* margin-top: 115px; */
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d8e0f9;
  --bs-table-striped-bg: #cdd5ed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2cae0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8cfe6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2cae0;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #ccf9ed;
  --bs-table-striped-bg: #c2ede1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8e0d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde6db;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8e0d5;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff2d1;
  --bs-table-striped-bg: #f2e6c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dabc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0c1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dabc;
}

.table-danger {
  --bs-table-bg: #fdd7e0;
  --bs-table-striped-bg: #f0ccd5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4c2ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eac7cf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4c2ca;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #9eb1f1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #9eb1f1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #9eb1f1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.form-check-input:checked {
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #3c63e2;
  border-color: #3c63e2;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239eb1f1'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3c63e2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c5d0f6;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3c63e2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c5d0f6;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00dfa3;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(0, 223, 163, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.form-control .was-validated :valid,
.form-control .is-valid {
  border-color: #00dfa3;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300dfa3' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control .was-validated :valid:focus,
.form-control .is-valid:focus {
  border-color: #00dfa3;
  box-shadow: 0 0 0 0.25rem rgba(0, 223, 163, 0.25);
}

textarea.form-control .was-validated :valid,
textarea.form-control .is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select .was-validated :valid,
.form-select .is-valid {
  border-color: #00dfa3;
}

.form-select .was-validated :valid:not([multiple]):not([size]), .form-select .was-validated :valid:not([multiple])[size="1"],
.form-select .is-valid:not([multiple]):not([size]),
.form-select .is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300dfa3' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select .was-validated :valid:focus,
.form-select .is-valid:focus {
  border-color: #00dfa3;
  box-shadow: 0 0 0 0.25rem rgba(0, 223, 163, 0.25);
}

.form-check-input .was-validated :valid,
.form-check-input .is-valid {
  border-color: #00dfa3;
}

.form-check-input .was-validated :valid:checked,
.form-check-input .is-valid:checked {
  background-color: #00dfa3;
}

.form-check-input .was-validated :valid:focus,
.form-check-input .is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 223, 163, 0.25);
}

.form-check-input .was-validated :valid ~ .form-check-label,
.form-check-input .is-valid ~ .form-check-label {
  color: #00dfa3;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.input-group .form-control .was-validated :valid,
.input-group .form-control .is-valid,
.input-group .form-select .was-validated :valid,
.input-group .form-select .is-valid {
  z-index: 1;
}

.input-group .form-control .was-validated :valid:focus,
.input-group .form-control .is-valid:focus,
.input-group .form-select .was-validated :valid:focus,
.input-group .form-select .is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f73563;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(247, 53, 99, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-control .was-validated :invalid,
.form-control .is-invalid {
  border-color: #f73563;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f73563'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f73563' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control .was-validated :invalid:focus,
.form-control .is-invalid:focus {
  border-color: #f73563;
  box-shadow: 0 0 0 0.25rem rgba(247, 53, 99, 0.25);
}

textarea.form-control .was-validated :invalid,
textarea.form-control .is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select .was-validated :invalid,
.form-select .is-invalid {
  border-color: #f73563;
}

.form-select .was-validated :invalid:not([multiple]):not([size]), .form-select .was-validated :invalid:not([multiple])[size="1"],
.form-select .is-invalid:not([multiple]):not([size]),
.form-select .is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f73563'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f73563' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select .was-validated :invalid:focus,
.form-select .is-invalid:focus {
  border-color: #f73563;
  box-shadow: 0 0 0 0.25rem rgba(247, 53, 99, 0.25);
}

.form-check-input .was-validated :invalid,
.form-check-input .is-invalid {
  border-color: #f73563;
}

.form-check-input .was-validated :invalid:checked,
.form-check-input .is-invalid:checked {
  background-color: #f73563;
}

.form-check-input .was-validated :invalid:focus,
.form-check-input .is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 53, 99, 0.25);
}

.form-check-input .was-validated :invalid ~ .form-check-label,
.form-check-input .is-invalid ~ .form-check-label {
  color: #f73563;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.input-group .form-control .was-validated :invalid,
.input-group .form-control .is-invalid,
.input-group .form-select .was-validated :invalid,
.input-group .form-select .is-invalid {
  z-index: 2;
}

.input-group .form-control .was-validated :invalid:focus,
.input-group .form-control .is-invalid:focus,
.input-group .form-select .was-validated :invalid:focus,
.input-group .form-select .is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #3354c0;
  border-color: #304fb5;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #3354c0;
  border-color: #304fb5;
  box-shadow: 0 0 0 0.25rem rgba(89, 122, 230, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #304fb5;
  border-color: #2d4aaa;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(89, 122, 230, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #000;
  background-color: #00dfa3;
  border-color: #00dfa3;
}

.btn-success:hover {
  color: #000;
  background-color: #26e4b1;
  border-color: #1ae2ac;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #26e4b1;
  border-color: #1ae2ac;
  box-shadow: 0 0 0 0.25rem rgba(0, 190, 139, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #33e5b5;
  border-color: #1ae2ac;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 190, 139, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #00dfa3;
  border-color: #00dfa3;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffbd17;
  border-color: #ffbd17;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffc73a;
  border-color: #ffc42e;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffc73a;
  border-color: #ffc42e;
  box-shadow: 0 0 0 0.25rem rgba(217, 161, 20, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffca45;
  border-color: #ffc42e;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 161, 20, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffbd17;
  border-color: #ffbd17;
}

.btn-danger {
  color: #000;
  background-color: #f73563;
  border-color: #f73563;
}

.btn-danger:hover {
  color: #000;
  background-color: #f8537a;
  border-color: #f84973;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #f8537a;
  border-color: #f84973;
  box-shadow: 0 0 0 0.25rem rgba(210, 45, 84, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #f95d82;
  border-color: #f84973;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(210, 45, 84, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #f73563;
  border-color: #f73563;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #3c63e2;
  border-color: #3c63e2;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #3c63e2;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #00dfa3;
  border-color: #00dfa3;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #00dfa3;
  border-color: #00dfa3;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 223, 163, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #00dfa3;
  border-color: #00dfa3;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 223, 163, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #00dfa3;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffbd17;
  border-color: #ffbd17;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffbd17;
  border-color: #ffbd17;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 189, 23, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffbd17;
  border-color: #ffbd17;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 189, 23, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffbd17;
  background-color: transparent;
}

.btn-outline-danger {
  color: #f73563;
  border-color: #f73563;
}

.btn-outline-danger:hover {
  color: #000;
  background-color: #f73563;
  border-color: #f73563;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 53, 99, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #f73563;
  border-color: #f73563;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 53, 99, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #f73563;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #3c63e2;
  text-decoration: underline;
}

.btn-link:hover {
  color: #304fb5;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3c63e2;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #3c63e2;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #3c63e2;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #304fb5;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3c63e2;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #3659cb;
  background-color: #eceffc;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233659cb'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #9eb1f1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "https://maxartkiller.com/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #3c63e2;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #304fb5;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #304fb5;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #243b88;
  background-color: #d8e0f9;
  border-color: #c5d0f6;
}

.alert-primary .alert-link {
  color: #1d2f6d;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #005941;
  background-color: #ccf9ed;
  border-color: #b3f5e3;
}

.alert-success .alert-link {
  color: #004734;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664c09;
  background-color: #fff2d1;
  border-color: #ffebb9;
}

.alert-warning .alert-link {
  color: #523d07;
}

.alert-danger {
  color: #94203b;
  background-color: #fdd7e0;
  border-color: #fdc2d0;
}

.alert-danger .alert-link {
  color: #761a2f;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3c63e2;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3c63e2;
  border-color: #3c63e2;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #243b88;
  background-color: #d8e0f9;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #243b88;
  background-color: #c2cae0;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #243b88;
  border-color: #243b88;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #005941;
  background-color: #ccf9ed;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #005941;
  background-color: #b8e0d5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #005941;
  border-color: #005941;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664c09;
  background-color: #fff2d1;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664c09;
  background-color: #e6dabc;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664c09;
  border-color: #664c09;
}

.list-group-item-danger {
  color: #94203b;
  background-color: #fdd7e0;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #94203b;
  background-color: #e4c2ca;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #94203b;
  border-color: #94203b;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(60, 99, 226, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #3c63e2;
}

.link-primary:hover, .link-primary:focus {
  color: #304fb5;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #00dfa3;
}

.link-success:hover, .link-success:focus {
  color: #33e5b5;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffbd17;
}

.link-warning:hover, .link-warning:focus {
  color: #ffca45;
}

.link-danger {
  color: #f73563;
}

.link-danger:hover, .link-danger:focus {
  color: #f95d82;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3c63e2 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #00dfa3 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffbd17 !important;
}

.border-danger {
  border-color: #f73563 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #3c63e2 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #00dfa3 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffbd17 !important;
}

.text-danger {
  color: #f73563 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #3c63e2 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #00dfa3 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffbd17 !important;
}

.bg-danger {
  background-color: #f73563 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*!
 finwallapp customize css starts here 
 * finwallapp v2 (https://maxartkiller.com/)
 * Copyright 2021 Maxartkiller 
 */
/* 2. variables */
:root {
  --finwallapp-green: #00dfa3;
  --finwallapp-red: #f73563;
  --finwallapp-yellow: #ffbd17;
  --finwallapp-blue: #3c63e2;
  --finwallapp-rounded: 20px;
  --finwallapp-padding: 15px;
  --finwallapp-input-rounded: 10px;
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #57bdff;
  --finwallapp-theme-color-grad-2: #4f77fb;
  --finwallapp-theme-color-grad-3: #6528cf;
  /* color schemes */
  --finwallapp-theme-color: #6500d8;
  --finwallapp-theme-text: #ffffff;
  --finwallapp-theme-text-primary: #000000;
  --finwallapp-theme-text-secondary: #999999;
  --finwallapp-theme-text-secondary-light: #bbbbbb;
  --finwallapp-theme-bordercolor: rgba(0, 0, 0, 0.1);
  --finwallapp-header: transparent;
  --finwallapp-header-active: #ffffff;
  --finwallapp-footer: #ffffff;
  --finwallapp-sidebar: var(--finwallapp-theme-color);
  --finwallapp-card-color: #ffffff;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #eff6f9;
  --finwallapp-page-bg-3: #faf0fb;
  --finwallapp-page-text: #000000;
  --finwallapp-page-link: var(--finwallapp-theme-color);
}

.theme-indigo {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #8b9dff;
  --finwallapp-theme-color-grad-2: #5c00f1;
  --finwallapp-theme-color-grad-3: #68008e;
  /* color schemes */
  --finwallapp-theme-color: #6610f2;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f1eff9;
  --finwallapp-page-bg-3: #f6f0fb;
}

.theme-purple {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #e908d8;
  --finwallapp-theme-color-grad-2: #5a2ab3;
  --finwallapp-theme-color-grad-3: #300284;
  /* color schemes */
  --finwallapp-theme-color: #6f42c1;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fff5fc;
  --finwallapp-page-bg-3: #f6f0fb;
}

.theme-pink {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ff71c3;
  --finwallapp-theme-color-grad-2: #e80075;
  --finwallapp-theme-color-grad-3: #4d00da;
  /* color schemes */
  --finwallapp-theme-color: #d63384;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f4f8ff;
  --finwallapp-page-bg-3: #ffe9f7;
}

.theme-red {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffc699;
  --finwallapp-theme-color-grad-2: #ff5d83;
  --finwallapp-theme-color-grad-3: #dc006a;
  /* color schemes */
  --finwallapp-theme-color: #f73563;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fffbf2;
  --finwallapp-page-bg-3: #ffeeee;
}

.theme-orange {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffda6b;
  --finwallapp-theme-color-grad-2: #ff7c15;
  --finwallapp-theme-color-grad-3: #e80774;
  /* color schemes */
  --finwallapp-theme-color: #fd7e14;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fffaf4;
  --finwallapp-page-bg-3: #fff4eb;
}

.theme-yellow {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffdb6f;
  --finwallapp-theme-color-grad-2: #f0ac00;
  --finwallapp-theme-color-grad-3: #f03000;
  /* color schemes */
  --finwallapp-theme-color: #ffbd17;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f9f4ef;
  --finwallapp-page-bg-3: #fbf8f0;
}

.theme-green {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #bdffaa;
  --finwallapp-theme-color-grad-2: #04c5de;
  --finwallapp-theme-color-grad-3: #00a0f5;
  /* color schemes */
  --finwallapp-theme-color: #00dfa3;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #eff6f9;
  --finwallapp-page-bg-3: #e6f7f0;
}

.theme-teal {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #a2fffa;
  --finwallapp-theme-color-grad-2: #00a2ad;
  --finwallapp-theme-color-grad-3: #3f51b5;
  /* color schemes */
  --finwallapp-theme-color: #0ab2be;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #fff7fd;
  --finwallapp-page-bg-3: #e8faf8;
}

.theme-cyan {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #74e5fb;
  --finwallapp-theme-color-grad-2: #258cd6;
  --finwallapp-theme-color-grad-3: #3d00ab;
  /* color schemes */
  --finwallapp-theme-color: #0dcaf0;
  --finwallapp-page-bg-1: #ffffff;
  --finwallapp-page-bg-2: #f1eff9;
  --finwallapp-page-bg-3: #f0f8fb;
}

@media screen and (max-width: 340px) {
  :root {
    --finwallapp-padding: 10px;
    --finwallapp-rounded: 12px;
  }
}

/* 3. utility */
.modal-dialog-ends {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1rem);
}

.border-left-5,
.card.border-left-5 {
  border-left-width: 5px;
  border-left-style: solid;
}

.dashed-line {
  border-top: 1px dashed rgba(0, 0, 0, 0.25);
  min-height: 1px;
}

.blur {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-99 {
  z-index: 99;
}

.overflow-x-auto {
  overflow-x: auto;
}

.btn-close,
.accordion-button::after {
  background-size: 50%;
  background-position: center center;
}

.coverimg {
  background-size: cover;
  background-position: center center;
}

.coverimg + div {
  position: relative;
  z-index: 1;
}

.coverimg + div + div {
  position: relative;
  z-index: 1;
}

.coverimg.right-center-img {
  background-size: auto;
  background-position: left center;
  background-repeat: no-repeat;
}

.coverimg.right-center-img {
  background-size: auto;
  background-position: right center;
  background-repeat: no-repeat;
}

.overlay {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: block;
  position: absolute;
  background: -moz-linear-gradient(top, transparent 0%, transparent 42%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, transparent 42%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, transparent 0%, transparent 42%, rgba(0, 0, 0, 0.65) 100%);
}

.transition-05, .bar-more > span, .header:after, .sidebar-wrap, .sidebar-wrap .sidebar, .sidebar-wrap.sidebar-pushcontent + main, .footer, .footer .nav .nav-item.centerbutton .nav-link > span i, .filter {
  -webkit-transition: ease all 0.5s;
  -moz-transition: ease all 0.5s;
  transition: ease all 0.5s;
}

.transition-1, .sidebar-wrap.sidebar-overlay::after {
  -webkit-transition: ease all 1s;
  -moz-transition: ease all 1s;
  transition: ease all 1s;
}

.rounded-10 {
  border-radius: calc(var(--finwallapp-rounded) - 10px) !important;
}

.rounded-10-end {
  border-radius: 0 calc(var(--finwallapp-rounded) - 5px) calc(var(--finwallapp-rounded) - 5px) 0;
}

.rounded-12 {
  border-radius: calc(var(--finwallapp-rounded) - 8px);
}

.rounded-15 {
  border-radius: calc(var(--finwallapp-rounded) - 5px) !important;
}

.rounded-15-end {
  border-radius: 0 calc(var(--finwallapp-rounded) - 5px) calc(var(--finwallapp-rounded) - 5px) 0;
}

.rounded-18 {
  border-radius: calc(var(--finwallapp-rounded) - 2px);
}

.rounded-20 {
  border-radius: var(--finwallapp-rounded) !important;
}

.h-1 {
  height: 1px;
}

.h-2 {
  height: 2px;
}

.h-4 {
  height: 4px;
}

.h-5 {
  height: 5px;
}

.h-190 {
  height: 190px;
}

.w-10 {
  width: 10px !important;
}

.w-12 {
  width: 12px !important;
}

.w-110 {
  min-width: 110px;
  width: 110px !important;
}

.size-10 {
  font-size: 10px;
  line-height: 12px;
}

.size-12 {
  font-size: 12px;
}

.size-18 {
  font-size: 18px;
}

.size-20 {
  font-size: 20px;
}

.size-22 {
  font-size: 22px;
}

.size-24 {
  font-size: 24px;
}

.size-32 {
  font-size: 32px;
}

.opacity-5 {
  opacity: 0.5;
}

.modal-backdrop.show {
  opacity: 0.15;
}

.vm {
  vertical-align: middle !important;
}

.start-auto {
  left: auto !important;
}

.shadow-success.shadow-sm {
  box-shadow: 0 3px 10px rgba(0, 223, 163, 0.25) !important;
}

.shadow-danger.shadow-sm {
  box-shadow: 0 3px 10px rgba(247, 53, 99, 0.2) !important;
}

.shadow-warning.shadow-sm {
  box-shadow: 0 3px 10px rgba(255, 189, 23, 0.3) !important;
}

.shadow-primary.shadow-sm {
  box-shadow: 0 3px 10px rgba(60, 99, 226, 0.2) !important;
}

.text-purple {
  color: #6f42c1;
}

.shadow-purple.shadow-sm {
  box-shadow: 0 3px 10px rgba(111, 66, 193, 0.2) !important;
}

.shadow-info.shadow-sm {
  box-shadow: 0 3px 10px rgba(13, 202, 240, 0.25) !important;
}

.shadow-secondary.shadow-sm {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
}

.aftercircle {
  position: relative;
  background-color: var(--finwallapp-card-color);
}

.aftercircle:after {
  content: '';
  position: absolute;
  height: calc(100% + 30px);
  width: 50px;
  right: -25px;
  border-radius: 50%;
  top: -15px;
  display: block;
  z-index: 1;
  background-color: var(--finwallapp-card-color);
}

.phone3d {
  box-shadow: 0 10px 20px rgba(30, 42, 90, 0.1), inset 4px 4px 6px rgba(0, 0, 0, 0.1), inset -3px -3px 10px rgba(176, 187, 230, 0.8);
  display: inline-block;
  padding: 40px 15px 15px 15px;
  border-radius: 36px;
  background: #fff;
  position: relative;
}

.phone3d:before {
  content: "";
  height: 8px;
  width: 100px;
  position: absolute;
  left: 50%;
  top: 20px;
  background: #f0f2fa;
  margin-left: -50px;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.phone3d:after {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  left: 50%;
  top: 20px;
  background: #f0f2fa;
  margin-left: 60px;
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.phone3d iframe {
  border-color: #fff;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  overflow: hidden;
  border: 2px solid #e1e6f5;
  height: 812px;
  width: 375px;
}

code,
pre code {
  color: #d63384;
  white-space: pre-wrap;
}

.clearfix {
  clear: both;
  width: 100%;
  display: flex;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .h-190 {
    height: 305px;
  }
}

/* 4. text */
.text-muted {
  opacity: 0.7;
  color: inherit !important;
}

h1, .h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  font-weight: 600;
  margin-bottom: 0;
}

h1:last-child, .h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

h1, .h1,
.h1 {
  font-size: 40px;
  line-height: 1.15;
}

p {
  margin-bottom: 10px;
}

p:last-child {
  margin-bottom: 0;
}

.small, small, .small {
  line-height: 1.22;
}

strong {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a,
.text-color-theme {
  color: var(--finwallapp-theme-color);
}

.text-secondary {
  color: var(--finwallapp-theme-text-secondary) !important;
}

.fw-medium {
  font-weight: 600;
}

.dark-bg {
  color: var(--finwallapp-theme-text);
}

.dark-bg a,
.dark-bg .text-color-theme {
  color: var(--finwallapp-theme-text);
}

.dark-bg .form-control,
.dark-bg label {
  color: #000000;
}

.text-normalcase {
  text-transform: none !important;
}

@media screen and (max-width: 340px) {
  h1, .h1, .h1 {
    font-size: 30px;
  }
}

/* 5. body */
html.menu-open {
  overflow: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  overflow-y: auto;
}

.theme-radial-gradient, .circle, .sidebar-wrap .sidebar .nav .nav-item .nav-link.active > .icon, .sidebar-wrap .sidebar .nav .nav-item .nav-link:hover > .icon {
  background: var(--finwallapp-theme-color-grad-1);
  background: -moz-radial-gradient(30% 30%, ellipse cover, var(--finwallapp-theme-color-grad-1) 0%, var(--finwallapp-theme-color-grad-2) 50%, var(--finwallapp-theme-color-grad-3) 100%);
  background: -webkit-radial-gradient(30% 30%, ellipse cover, var(--finwallapp-theme-color-grad-1) 0%, var(--finwallapp-theme-color-grad-2) 50%, var(--finwallapp-theme-color-grad-3) 100%);
  background: radial-gradient(ellipse at 30% 30%, var(--finwallapp-theme-color-grad-1) 0%, var(--finwallapp-theme-color-grad-2) 50%, var(--finwallapp-theme-color-grad-3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--finwallapp-theme-color-grad-1)', endColorstr='var(--finwallapp-theme-color-grad-3)',GradientType=1 );
}

.theme-light-radial-gradient, body, .loader-wrap {
  background: var(--finwallapp-page-bg-2);
  background: -moz-radial-gradient(30% 30%, ellipse cover, var(--finwallapp-page-bg-1) 0%, var(--finwallapp-page-bg-2) 50%, var(--finwallapp-page-bg-3) 100%);
  background: -webkit-radial-gradient(30% 30%, ellipse cover, var(--finwallapp-page-bg-1) 0%, var(--finwallapp-page-bg-2) 50%, var(--finwallapp-page-bg-3) 100%);
  background: radial-gradient(ellipse at 30% 30%, var(--finwallapp-page-bg-1) 0%, var(--finwallapp-page-bg-2) 50%, var(--finwallapp-page-bg-3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--finwallapp-page-bg-1)', endColorstr='var(--finwallapp-page-bg-3)',GradientType=1 );
  background-attachment: fixed;
}

main.bg-1 {
  background: url("../img/bg1.png") fixed;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
}

main.bg-2 {
  background: url("../img/bg2.png") fixed;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center right;
}

main.bg-3 {
  background: url("../img/bg3.png") fixed;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: 35% bottom;
}

.bg-none {
  background-color: transparent;
}

.bg-opac {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.bg-opac-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.border-opac {
  border-color: rgba(255, 255, 255, 0.45) !important;
}

.alert-danger,
.card.alert-danger,
.bg-danger-light {
  background-color: #feeff3;
}

.alert-info,
.card.alert-info,
.bg-info-light {
  background-color: #91f2f9;
}

.alert-primary,
.card.alert-primary,
.bg-primary-light {
  background-color: #e5eafb;
}

.alert-warning,
.card.alert-warning,
.bg-warning-light {
  background-color: #fff4d9;
}

.alert-success,
.card.alert-success,
.bg-success-light {
  background-color: #a2ffe6;
}

.alert-theme,
.card.alert-theme,
.bg-theme-light {
  background-color: var(--finwallapp-theme-color-light);
}

.bg-theme,
.modal-backdrop {
  background: var(--finwallapp-theme-color) !important;
  color: var(--finwallapp-theme-text) !important;
}

.border-dashed {
  border-style: dashed;
}

.circle-small {
  height: 44px;
  width: 44px;
  position: relative;
}

.circle-small > div:first-child {
  position: relative;
  width: 100%;
}

.circle-small > .avatar {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  margin: 7px;
}

.chartdoughnut {
  width: 200px;
  margin: 0 auto;
  position: relative;
}

.chartdoughnut > .datadisplay {
  position: absolute;
  left: 0;
  right: 0;
  margin: 35px auto;
  text-align: center;
  display: block;
  border-radius: 100px;
  height: 130px;
  width: 130px;
  padding: 35px 0;
  background-color: var(--finwallapp-theme-text);
}

.smallchart {
  width: 100.4%;
  padding: 0;
  display: block;
  height: 70px;
  margin-bottom: 10px;
  border-radius: 0 0 var(--finwallapp-padding) var(--finwallapp-padding);
}

.areachartsmall {
  width: 80px !important;
  height: 40px;
}

/* theme color */
.bg-blue {
  background-color: #3c63e2;
}

.bg-indigo {
  background-color: #6610f2;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-pink {
  background-color: #d63384;
}

.bg-red {
  background-color: #f73563;
}

.bg-orange {
  background-color: #fd7e14;
}

.bg-yellow {
  background-color: #ffbd17;
}

.bg-green {
  background-color: #00dfa3;
}

.bg-teal {
  background-color: #0ab2be;
}

.bg-cyan {
  background-color: #0dcaf0;
}

/* splash */
.bg-1-splash {
  height: 100px;
  top: -33px;
  position: absolute;
  z-index: 0;
  left: -20px;
}

radialGradient stop:first-child {
  stop-color: var(--finwallapp-theme-color-grad-1);
}

radialGradient stop:last-child {
  stop-color: var(--finwallapp-theme-color-grad-3);
}

linearGradient stop:first-child {
  stop-color: var(--finwallapp-theme-color-grad-1);
}

linearGradient stop:last-child {
  stop-color: var(--finwallapp-theme-color-grad-3);
}

.circle {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.circle.small {
  height: 34px;
  width: 34px;
}

.bg-2-splash {
  height: auto;
  max-width: 80%;
  bottom: 0px;
  position: absolute;
  z-index: 0;
  right: 0px;
}

.online-status {
  height: 10px;
  width: 10px;
  margin: 12px;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.cardimg {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
}

.cardimg + * {
  position: relative;
  z-index: 1;
}

.iwatchposition {
  position: absolute;
  left: 50%;
  top: -16px;
  margin-left: -45px;
  width: 90px;
}

/* prfile page*/
.profile-page {
  position: relative;
  margin-bottom: 20px;
  overflow-x: hidden;
}

.profile-page .menubg {
  width: 72%;
  position: absolute;
  z-index: 0;
  right: -6%;
}

.profile-page .menubg + * {
  position: relative;
  z-index: 1;
}

.profile-page .circle.one {
  left: 56%;
  top: 4%;
  z-index: 1;
  height: 20px;
  width: 20px;
}

.profile-page .circle.two {
  right: 18%;
  bottom: 5%;
  z-index: 1;
  height: 40px;
  width: 40px;
}

/* webkit - scrollbar*/
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (min-device-width: 300px) and (max-device-width: 1023px) and (orientation: landscape) {
  .col-ld-1 {
    width: 8.33333%;
  }
  .col-ld-2 {
    width: 16.66667%;
  }
  .col-ld-3 {
    width: 25%;
  }
  .col-ld-4 {
    width: 33.33333%;
  }
  .col-ld-5 {
    width: 41.66667%;
  }
  .col-ld-6 {
    width: 50%;
  }
  .col-ld-7 {
    width: 58.33333%;
  }
  .col-ld-8 {
    width: 66.66667%;
  }
  .col-ld-9 {
    width: 75%;
  }
  .col-ld-10 {
    width: 83.33333%;
  }
  .col-ld-11 {
    width: 91.66667%;
  }
  .col-ld-12 {
    width: 100%;
  }
}

@media screen and (max-width: 340px) {
  :root {
    --finwallapp-padding: 10px;
    --finwallapp-rounded: 18px;
  }
  body {
    font-size: 15px !important;
  }
  .logo-small img {
    border-radius: var(--finwallapp-rounded) !important;
  }
  .size-12 {
    font-size: 11px !important;
  }
  .mb-4 {
    margin-bottom: 20px !important;
  }
  .mb-3 {
    margin-bottom: 15px !important;
  }
  .mb-2 {
    margin-bottom: 10px !important;
  }
  .mb-1 {
    margin-bottom: 5px !important;
  }
}

/* 6. button */
.btn {
  font-size: 16px;
  text-transform: uppercase;
  border-radius: calc(var(--finwallapp-rounded) - 5px);
  position: relative;
}
.version{
  color: #e2a12b !important;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: calc(var(--finwallapp-rounded) - 5px);
  position: relative;
}

.btn.shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.btn.btn-sm, .btn-group-sm > .btn {
  font-size: 14px;
  border-radius: calc(var(--finwallapp-rounded) - 7px);
}

.btn:hover, .btn:focus {
  outline: none;
  box-shadow: none;
}

.btn i {
  font-size: 17px;
}

.btn-lg, .btn-group-lg > .btn,
.btn-group-lg > .btn {
  padding: var(--finwallapp-padding) calc(var(--finwallapp-padding) + 10px);
  line-height: 28px;
}

.btn-44 {
  height: 44px;
  line-height: 42px;
  width: 44px;
  padding: 0 !important;
}

.btn-26 {
  height: 26px;
  line-height: 24px;
  width: 26px;
  padding: 0 !important;
}

.btn-default {
  background-color: var(--finwallapp-theme-color);
  color: var(--finwallapp-theme-text);
}

.btn-default:active, .btn-default:hover {
  color: var(--finwallapp-theme-text);
}

.btn-default:disabled {
  background-color: var(--finwallapp-theme-bordercolor);
  color: var(--finwallapp-theme-color);
}

.btn-light {
  background-color: var(--finwallapp-theme-text);
  color: var(--finwallapp-theme-text-secondary);
}

.btn-icon-text {
  padding: 0;
}

.btn-icon-text i {
  height: 60px;
  line-height: 58px;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  border-radius: var(--finwallapp-rounded);
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  margin-bottom: 5px;
}

.btn-icon-text span {
  display: block !important;
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.65);
}

.dark-bg .btn-light {
  background-color: rgba(0, 0, 0, 0.15);
  border-color: rgba(0, 0, 0, 0.05);
  color: var(--finwallapp-theme-text);
}

.dark-bg .btn-default {
  background-color: #000000;
  color: #ffffff;
}

.dark-bg .btn-default:active, .dark-bg .btn-default:hover {
  color: #ffffff;
}

.count-indicator {
  height: 6px;
  width: 6px;
  border-radius: 6px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--finwallapp-red);
}

.bar-more > span {
  height: 4px;
  width: 80%;
  border-radius: 5px;
  display: inline-block;
  max-width: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.bar-more.collapsed > span {
  height: 6px;
  width: 60px;
  background-color: rgba(255, 255, 255, 0.4);
}

.counter-number {
  border: 1px solid var(--finwallapp-theme-text);
  background: var(--finwallapp-theme-text);
  border-radius: 20px;
  padding: 1px;
  display: inline-block;
}

.counter-number > span {
  min-width: 18px;
  display: inline-block;
  text-align: center;
}

.counter-number .btn {
  border: 1px solid var(--finwallapp-theme-text-secondary-light);
  background-color: var(--finwallapp-theme-text);
  color: var(--finwallapp-theme-color);
}

/* 7. Form elements */
.form-floating {
  position: relative;
}

.form-floating > label {
  padding: var(--finwallapp-padding) 0;
  line-height: 32px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  font-size: 13px;
  padding: 5px 0 var(--finwallapp-padding) 0;
  line-height: 28px;
  height: auto;
  transform: scale(0.85) translateY(-0.5rem) translateX(0rem);
}

.form-floating .form-control {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: var(--finwallapp-theme-bordercolor);
  border-radius: 0;
  padding: var(--finwallapp-padding) 0 var(--finwallapp-padding) 0;
  height: auto;
  line-height: 30px;
  -webkit-appearance: none;
}

.form-floating .form-control:focus, .form-floating .form-control:not(:placeholder-shown) {
  padding-top: calc(var(--finwallapp-padding) + 13px);
  padding-bottom: calc(var(--finwallapp-padding) - 3px);
  line-height: 20px;
}

.form-floating.is-valid:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  background-color: var(--finwallapp-green);
}

.form-floating.is-invalid:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  background-color: var(--finwallapp-red);
}

.form-control {
  border-color: var(--finwallapp-theme-bordercolor);
  border-radius: var(--finwallapp-rounded);
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.form-static {
  position: relative;
}

.form-static > label {
  padding: 0;
  line-height: 18px;
  opacity: 0.65;
  font-size: 13px;
}

.form-static .form-control {
  background-color: transparent;
  border-width: 0 0 1px 0;
  border-color: var(--finwallapp-theme-bordercolor);
  border-radius: 0;
  padding: 5px 0;
  height: auto;
  line-height: 30px;
  -webkit-appearance: none;
}

.form-static.is-valid:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  background-color: var(--finwallapp-green);
}

.form-static.is-invalid:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  background-color: var(--finwallapp-red);
}

.trasparent-input {
  font-size: 50px;
  line-height: 68px;
  padding: 10px 15px;
  border: 0;
  background-color: transparent;
  display: block;
  width: 100%;
  border-radius: var(--finwallapp-rounded);
}

.trasparent-input:focus {
  background-color: var(--finwallapp-theme-bordercolor);
  outline: none;
}

.tooltip-btn {
  background: none;
  border: 0;
  position: absolute;
  z-index: 2;
  top: 50%;
  margin-top: -18px;
  right: 0px;
  padding: 5px 10px;
  line-height: 26px;
}

.search-header {
  position: relative;
}

.search-header .form-control {
  padding: 0.625rem 0.75rem;
}

/* range picker slider */
.noUi-target {
  background-color: var(--finwallapp-theme-color-light);
  box-shadow: none;
  -webkit-box-shadow: none;
}

.noUi-target.noUi-horizontal {
  height: 10px;
}

.noUi-target .noUi-origin .noUi-handle {
  height: 30px;
  width: 30px;
  top: -11px;
  border: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.noUi-target .noUi-origin .noUi-handle:before {
  display: none;
}

.noUi-target .noUi-origin .noUi-handle:after {
  display: none;
}

.noUi-target .noUi-connect {
  background-color: var(--finwallapp-theme-color);
}

.number-incrementer {
  vertical-align: middle;
}

.number-incrementer .btn {
  padding: 0 10px;
  line-height: 18px;
  margin: 0;
}

.number-incrementer p {
  line-height: 16px;
  display: block;
  font-size: 12px;
}

.form-check.avatar {
  display: block;
  position: relative;
  padding: 0;
}

.form-check.avatar .form-check-input {
  position: absolute;
  left: 3px;
  bottom: 3px;
  margin-left: 0;
  z-index: 1;
  border-radius: 10px;
  opacity: 0.5;
}

.form-check.avatar .form-check-input:checked {
  opacity: 1;
}

/* 8. loader */
.loader-wrap {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.loader-wrap .loader-cube-wrap {
  width: 100px;
}

.progressstimer {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  margin: 0 auto 10px auto;
  position: relative;
}

.progressstimer img {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  -webkit-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.progressstimer .timer {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* 9. header */
.header {
  padding: var(--finwallapp-padding);
  line-height: 44px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 8;
  position: relative;
}

.header > * {
  z-index: 1;
  position: relative;
}

.header:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 0 0 var(--finwallapp-rounded) var(--finwallapp-rounded);
  background-color: var(--finwallapp-header-active);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  -webkt-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 0;
  opacity: 0;
}

.header.active:after {
  opacity: 0.95;
}

.header.active > * {
  z-index: 1;
  position: relative;
}

.header p {
  line-height: 20px;
}

/* 10. avatar */
.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

.avatar-group .avatar {
  transition: transform 0.3s ease, opacity 0.3s ease;
  transition: margin-left 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.avatar-group .avatar:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
  margin-left: 10px !important;
}

.avatar-group:hover .avatar {
  opacity: 0.5; /* Fade out others when hovering over one avatar */
  
}

.avatar-group .avatar:hover {
  opacity: 1; /* Keep the hovered avatar fully visible */
  transform: scale(1.2); /* Highlight hovered avatar */
  z-index: 999; /* Keep hovered avatar on top of other avatars */
}

.avatar > .icons {
  line-height: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.avatar > i {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.avatar > img {
  width: 100%;
  vertical-align: top;
}

.avatar.avatar-6 {
  line-height: 6px;
  height: 6px;
  width: 6px;
}

.avatar.avatar-10 {
  line-height: 10px;
  height: 10px;
  width: 10px;
}

.avatar.avatar-15 {
  line-height: 15px;
  height: 15px;
  width: 15px;
}

.avatar.avatar-20 {
  line-height: 20px;
  height: 20px;
  width: 20px;
}

.avatar.avatar-30 {
  line-height: 30px;
  height: 30px;
  width: 30px;
}

.avatar.avatar-36 {
  line-height: 36px;
  height: 36px;
  width: 36px;
}

.avatar.avatar-40 {
  line-height: 40px;
  height: 40px;
  width: 40px;
}

.avatar.avatar-44 {
  line-height: 44px;
  height: 44px;
  width: 44px;
}

.avatar.avatar-50 {
  line-height: 50px;
  height: 50px;
  width: 50px;
}

.avatar.avatar-60 {
  line-height: 60px;
  height: 60px;
  width: 60px;
}

.avatar.avatar-70 {
  line-height: 70px;
  height: 70px;
  width: 70px;
}

.avatar.avatar-80 {
  line-height: 80px;
  height: 80px;
  width: 80px;
}

.avatar.avatar-90 {
  line-height: 90px;
  height: 90px;
  width: 90px;
}

.avatar.avatar-100 {
  line-height: 100px;
  height: 100px;
  width: 100px;
}

.avatar.avatar-110 {
  line-height: 110px;
  height: 110px;
  width: 110px;
}

.avatar.avatar-120 {
  line-height: 120px;
  height: 120px;
  width: 120px;
}

.avatar.avatar-130 {
  line-height: 130px;
  height: 130px;
  width: 130px;
}

.avatar.avatar-140 {
  line-height: 140px;
  height: 140px;
  width: 140px;
}

.avatar.avatar-150 {
  line-height: 150px;
  height: 150px;
  width: 150px;
}

.avatar.avatar-160 {
  line-height: 160px;
  height: 160px;
  width: 160px;
}

.avatar-group {
  z-index: 1;
  display: flex;
}

.avatar-group .avatar {
  z-index: 1;
  margin-left: -20px;
}

.avatar-group .avatar:nth-child(1) {
  z-index: 6;
}

.avatar-group .avatar:nth-child(2) {
  z-index: 5;
}

.avatar-group .avatar:nth-child(3) {
  z-index: 4;
}

.avatar-group .avatar:nth-child(4) {
  z-index: 3;
}

.avatar-group .avatar:nth-child(5) {
  z-index: 2;
}

.avatar-group .avatar:nth-child(6) {
  z-index: 1;
}

.avatar-group .avatar:nth-child(7) {
  z-index: 0;
}

.avatar-group .avatar:nth-child(1) {
  margin-left: 0;
}

/* 11. sidebar */
.sidebar-wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;
  opacity: 0;
  left: -100%;
  padding: var(--finwallapp-padding);
}

.sidebar-wrap .sidebar {
  float: left;
  display: block;
  min-height: 100%;
  padding: var(--finwallapp-padding);
  position: relative;
  width: 280px;
  z-index: 1;
  border-radius: var(--finwallapp-rounded);
  color: var(--finwallapp-theme-text);
}

.sidebar-wrap .sidebar .profile-sidebar {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-wrap .sidebar .profile-sidebar .menubg {
  width: 72%;
  position: absolute;
  z-index: 0;
  right: 3%;
}

.sidebar-wrap .sidebar .profile-sidebar .menubg + * {
  position: relative;
  z-index: 1;
}

.sidebar-wrap .sidebar .profile-sidebar .circle.one {
  right: 1%;
  top: 4%;
  z-index: 1;
  height: 20px;
  width: 20px;
}

.sidebar-wrap .sidebar .profile-sidebar .circle.two {
  left: 56%;
  bottom: -30%;
  z-index: 1;
  height: 30px;
  width: 30px;
}

.sidebar-wrap .sidebar .nav {
  flex-direction: column;
}

.sidebar-wrap .sidebar .nav .nav-item {
  margin-bottom: 10px;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle {
  color: var(--finwallapp-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle > .arrow {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle > .arrow > .plus {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle > .arrow > .minus {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show {
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0 0;
  background-color: rgba(255, 255, 255, 0.15);
  color: var(--finwallapp-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show > .arrow > .plus {
  display: none;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-toggle.show > .arrow > .minus {
  display: block;
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.15);
  border: 0;
  padding: 5px;
  border-radius: 0 0 var(--finwallapp-rounded) var(--finwallapp-rounded);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu > li {
  margin-bottom: 5px;
  color: var(--finwallapp-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item.dropdown .dropdown-menu > li:last-child {
  margin-bottom: 0;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link {
  display: flex;
  line-height: 40px;
  font-size: 16px;
  padding: 5px;
  border-radius: var(--finwallapp-rounded);
  color: var(--finwallapp-theme-text);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link > * {
  line-height: 40px;
  vertical-align: middle;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link > .arrow {
  opacity: 0.3;
  margin-right: 15px;
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link > .icon {
  margin-right: 15px;
  border-radius: calc(var(--finwallapp-rounded) - 5px);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active, .sidebar-wrap .sidebar .nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-wrap .sidebar .nav .nav-item .nav-link.active > .arrow, .sidebar-wrap .sidebar .nav .nav-item .nav-link:hover > .arrow {
  opacity: 0.5;
}

.sidebar-wrap.sidebar-overlay::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--finwallapp-theme-color);
  opacity: 0;
}

.sidebar-wrap.sidebar-overlay .sidebar {
  width: 280px;
  left: -280px;
  background-color: var(--finwallapp-theme-color);
  z-index: 1;
}

.sidebar-wrap.sidebar-overlay .sidebar .nav .nav-item.dropdown .dropdown-menu {
  position: relative !important;
  transform: none !important;
}

.sidebar-wrap.sidebar-pushcontent {
  background-color: transparent;
  width: 280px;
  left: -280px;
}

.sidebar-wrap.sidebar-pushcontent + main {
  margin-left: 0;
  width: 100%;
}

.sidebar-wrap.sidebar-pushcontent .sidebar {
  background-color: var(--finwallapp-theme-color);
  width: 100%;
  left: 0;
}

.sidebar-wrap.sidebar-pushcontent .sidebar .nav .nav-item.dropdown .dropdown-menu {
  position: relative !important;
  transform: none !important;
}

.sidebar-wrap.sidebar-pushcontent .closemenu {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu {
  background: var(--finwallapp-theme-color);
}

.sidebar-wrap.sidebar-fullmenu .closemenu {
  position: fixed;
  background: transparent;
  transform: none;
  bottom: 0;
  right: 0;
  margin: 0px auto;
  color: #fff !important;
  z-index: 2;
  height: 66px;
  width: 66px;
  border-left: 33px solid transparent;
  border-bottom: 33px solid rgba(0, 0, 0, 0.2);
  border-right: 33px solid rgba(0, 0, 0, 0.1);
  border-top: 33px solid transparent;
  opacity: 1;
}

.sidebar-wrap.sidebar-fullmenu .closemenu:after {
  content: "x";
  position: absolute;
  bottom: -20px;
  right: -20px;
  font-size: 14px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  background: #000;
  text-align: center;
  display: inline-block;
  letter-spacing: 0;
  border-radius: 15px;
  text-indent: 0;
}

.sidebar-wrap.sidebar-fullmenu .sidebar {
  width: 100%;
  left: 0;
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .profile-sidebar {
  position: relative;
  max-width: 80%;
  margin: 0 auto 30px auto;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav {
  width: 80%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item {
  width: 32%;
  margin-right: 2%;
  text-align: center;
  line-height: 30px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item:nth-child(3n) {
  margin-right: 0;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link {
  flex-direction: column;
  font-size: 12px;
  padding-top: 10px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link > * {
  line-height: 30px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link .icon {
  margin: 0 auto;
  font-size: 18px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link .arrow {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item .nav-link.active .arrow {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle > .arrow {
  display: block;
  position: absolute;
  margin: 5px;
  line-height: 25px;
  width: 10px;
  text-align: center;
  right: 0;
  bottom: 0;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle > .arrow .plus {
  display: block;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle > .arrow .minus {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle.show > .arrow .plus {
  display: none;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-toggle.show > .arrow .minus {
  display: block;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu {
  background-color: var(--finwallapp-theme-color);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: -2px !important;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu li {
  width: 100%;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu li .nav-link {
  flex-direction: row;
  font-size: 14px;
  padding-top: 0;
  line-height: 40px;
}

.sidebar-wrap.sidebar-fullmenu .sidebar .nav .nav-item.dropdown .dropdown-menu li .nav-link .icon {
  margin: 0 5px 0 auto;
  font-size: 16px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.sidebar-wrap .closemenu {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0;
  text-transform: uppercase;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  z-index: 1;
  padding: 0;
  background: transparent;
  border-radius: 0;
  color: #fff !important;
  text-indent: 100%;
  width: 100%;
  height: 100%;
}

.menu-open {
  overflow: hidden;
}

.menu-open .sidebar-wrap {
  left: 0;
  opacity: 1;
}

.menu-open .sidebar-wrap.sidebar-overlay::after {
  opacity: 0.2;
}

.menu-open .sidebar-wrap.sidebar-overlay .sidebar {
  left: 0;
}

.menu-open .sidebar-wrap.sidebar-pushcontent + main {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  margin-left: 280px;
  position: relative;
  z-index: 99;
}

.page-sidebar {
  width: 280px;
}

@media screen and (max-width: 340px) {
  .sidebar-wrap .sidebar .nav .nav-item .nav-link {
    line-height: 30px;
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* 12. card */
.card {
  background-color: var(--finwallapp-card-color);
  color: var(--finwallapp-card-text);
  border-radius: var(--finwallapp-rounded);
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card .card-header {
  background-color: transparent;
  border-color: var(--finwallapp-theme-bordercolor);
  padding: var(--finwallapp-padding);
  border-top-left-radius: var(--finwallapp-rounded);
  border-top-right-radius: var(--finwallapp-rounded);
}

.card .card-body {
  padding: var(--finwallapp-padding);
}

.card .card-footer {
  background-color: transparent;
  border-color: var(--finwallapp-theme-bordercolor);
  padding: var(--finwallapp-padding);
  border-bottom-left-radius: var(--finwallapp-rounded);
  border-bottom-right-radius: var(--finwallapp-rounded);
}

.card.theme-bg, .card.dark-bg, .card.bg-danger, .card.bg-success, .card.bg-primary, .card.bg-warning, .card.bg-info, .card.bg-dark, .card.theme-radial-gradient, .card.circle, .sidebar-wrap .sidebar .nav .nav-item .nav-link.active > .card.icon, .sidebar-wrap .sidebar .nav .nav-item .nav-link:hover > .card.icon, .card.bg-opac {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.18);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.18);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.18);
  background-color: var(--finwallapp-theme-color);
  color: var(--finwallapp-theme-text);
}

.card.selected {
  border: 2px solid var(--finwallapp-green);
}

.card > .card.bg-opac {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

a.card {
  text-decoration: none;
}

.toast {
  border-radius: var(--finwallapp-rounded);
}

.toast .toast-header {
  padding: calc(var(--finwallapp-padding) - 4px) var(--finwallapp-padding);
  border-top-left-radius: var(--finwallapp-rounded);
  border-top-right-radius: var(--finwallapp-rounded);
}

.toast .toast-body {
  padding: var(--finwallapp-padding);
}

.toast .toast-footer {
  padding: calc(var(--finwallapp-padding) - 4px) var(--finwallapp-padding);
  border-bottom-left-radius: var(--finwallapp-rounded);
  border-bottom-right-radius: var(--finwallapp-rounded);
}

.modal .modal-dialog .modal-content {
  border-radius: var(--finwallapp-rounded);
  border: 0;
}

.modal .modal-dialog.modal-xsm {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.accordion {
  border-radius: var(--finwallapp-rounded);
}

.accordion .accordion-item:first-child {
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0 0;
}

.accordion .accordion-item:first-child .accordion-header {
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0 0;
}

.accordion .accordion-item:first-child .accordion-header .accordion-button {
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0 0;
}

.accordion .accordion-item:last-child {
  border-radius: 0 0 var(--finwallapp-rounded) var(--finwallapp-rounded);
}

.accordion .accordion-item:last-child .accordion-header {
  border-radius: 0 0 var(--finwallapp-rounded) var(--finwallapp-rounded);
}

.accordion .accordion-item:last-child .accordion-header .accordion-button.collapsed {
  border-radius: 0 0 var(--finwallapp-rounded) var(--finwallapp-rounded);
}

@media screen and (max-width: 340px) {
  .toast {
    width: 300px;
  }
}

/* 13. swiper sliders */
.swiper-container {
  z-index: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: var(--finwallapp-theme-color);
}

.pagination-rightnumber {
  position: absolute;
  top: 45%;
  right: 15px;
  left: auto !important;
  width: auto;
  text-align: right;
}

.pagination-rightnumber .swiper-pagination-bullet {
  background: none !important;
  color: var(--finwallapp-theme-text-secondary);
  height: 30px;
  line-height: 30px;
  position: relative;
  display: block;
  width: 100%;
  opacity: 0.5;
}

.pagination-rightnumber .swiper-pagination-bullet::after {
  content: "";
  height: 2px;
  width: 15px;
  border-radius: 2px;
  background-color: var(--finwallapp-theme-text-secondary);
  display: block;
  position: absolute;
  right: 15px;
  top: 49%;
}

.pagination-rightnumber .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--finwallapp-theme-color);
  opacity: 1;
}

.pagination-rightnumber .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  width: 30px;
  background-color: var(--finwallapp-theme-color);
}

.introswiper {
  height: 100%;
}

.introswiper .swiper-wrapper .swiper-slide {
  overflow: hidden;
}

.introswiper .text {
  font-size: 84px;
  opacity: 0.2;
  color: var(--finwallapp-theme-text);
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  top: 35%;
  right: 0;
}

.introswiper .slidebg {
  right: -45px;
  position: relative;
}

.introswiper .slideimg {
  margin-left: -145px;
  position: absolute;
  left: 0;
  top: -30px;
}

.introswiper .circle.one {
  left: 58%;
  top: 4%;
}

.introswiper .circle.two {
  right: 6%;
  top: 48%;
  z-index: 1;
}

.introswiper .circle.three {
  left: 24%;
  top: 72%;
}

.introswiper .swiper-slide-two .text {
  top: 6%;
  right: 0;
}

.introswiper .swiper-slide-two .slidebg {
  right: 130px;
  position: relative;
}

.introswiper .swiper-slide-two .slideimg {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  top: auto;
}

.introswiper .swiper-slide-two .circle.one {
  left: 8%;
  top: 47%;
}

.introswiper .swiper-slide-two .circle.two {
  right: 14%;
  top: 8%;
  z-index: 1;
}

.introswiper .swiper-slide-two .circle.three {
  left: 65%;
  top: 80%;
  z-index: 1;
}

.introswiper .swiper-slide-three .text {
  font-size: 74px;
  top: 15%;
  right: 0;
  left: 0;
}

.introswiper .swiper-slide-three .slidebg {
  right: 40px;
  position: relative;
  margin-bottom: 60px;
}

.introswiper .swiper-slide-three .slideimg {
  margin: 0;
  position: absolute;
  right: 0;
  left: auto;
  top: 3%;
}

.introswiper .swiper-slide-three .slideimg2 {
  margin: 0;
  position: absolute;
  left: -20px;
  bottom: 0px;
}

.introswiper .swiper-slide-three .circle.one {
  left: 8%;
  top: 40%;
}

.introswiper .swiper-slide-three .circle.two {
  right: 10%;
  top: 58%;
  z-index: 1;
}

.introswiper .swiper-slide-three .circle.three {
  left: 30%;
  top: 0%;
  z-index: 1;
}

.cardswiper .swiper-wrapper .swiper-slide {
  width: 280px;
  padding: 0 5px 10px 15px;
}

.cardswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--finwallapp-padding);
}

.cardswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--finwallapp-padding);
}

.cardswiper .swiper-wrapper .swiper-slide .card {
  min-height: 150px;
}

.connectionwiper .swiper-wrapper .swiper-slide {
  width: auto;
  padding: 0 5px 10px 15px;
}

.connectionwiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--finwallapp-padding);
}

.connectionwiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--finwallapp-padding);
}

.summayswiper .swiper-wrapper .swiper-slide {
  width: 200px;
  padding: 0 5px 10px 15px;
}

.summayswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--finwallapp-padding);
}

.summayswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--finwallapp-padding);
}

.tagsswiper .swiper-wrapper .swiper-slide {
  width: auto;
  padding: 0 5px 10px 5px;
}

.tagsswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: var(--finwallapp-padding);
}

.tagsswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: var(--finwallapp-padding);
}

@media screen and (max-width: 340px) {
  .introswiper .slidebg {
    max-height: 150px;
  }
  .introswiper .swiper-slide .slideimg {
    max-height: 200px;
  }
  .introswiper .swiper-slide-two .slideimg {
    max-height: 120px;
  }
  .introswiper .swiper-slide-three .slideimg {
    max-height: 70px;
  }
  .introswiper .swiper-slide-three .slideimg2 {
    max-height: 180px;
  }
}

/* 14. list */
.list-group .list-group-item {
  padding: var(--finwallapp-padding);
}

.list-group.bg-none .list-group-item {
  background-color: transparent;
  border-color: var(--finwallapp-theme-bordercolor);
}

/* chat list */
.chat-list {
  margin: 0px auto 0px auto;
  font-size: 15px;
}

.chat-list .figure,
.chat-list figure,
.chat-list iframe,
.chat-list video {
  border-radius: calc(var(--finwallapp-rounded) - 5px);
  overflow: hidden;
  display: block;
  max-width: 100%;
}

.chat-list .left-chat {
  margin-bottom: 15px;
}

.chat-list .left-chat .chat-block {
  background-color: var(--finwallapp-theme-text);
  padding: 15px;
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) var(--finwallapp-rounded) 0px;
  margin-right: 3px;
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 90%;
}

.chat-list .left-chat .chat-block:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -16px;
  height: 8px;
  width: 8px;
  border-radius: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  z-index: 1;
  border-top-color: var(--finwallapp-theme-text);
  border-left-color: var(--finwallapp-theme-text);
}

.chat-list .left-chat .time {
  padding-left: 15px;
}

.chat-list .right-chat {
  margin-bottom: 15px;
  text-align: right;
}

.chat-list .right-chat .chat-block {
  text-align: left;
  background-color: var(--finwallapp-theme-color);
  color: var(--finwallapp-theme-text);
  padding: 15px;
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0px var(--finwallapp-rounded);
  margin-left: 3px;
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 90%;
}

.chat-list .right-chat .chat-block:before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -16px;
  height: 8px;
  width: 8px;
  border-radius: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  border-right-color: var(--finwallapp-theme-color);
  border-top-color: var(--finwallapp-theme-color);
  z-index: 0;
}

.chat-list .right-chat .time {
  padding-right: 15px;
}

.log-information li {
  border: 0;
  padding: 0px 15px 15px 40px !important;
  background-color: transparent;
}

.log-information li:after {
  content: "";
  height: 100%;
  width: 1px;
  background: rgba(121, 121, 121, 0.2);
  display: block;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 0;
}

.log-information li:before {
  content: "";
  height: 1px;
  width: 20px;
  background: rgba(121, 121, 121, 0.2);
  display: block;
  position: absolute;
  left: 20px;
  top: 22px;
  z-index: 0;
}

.log-information li .avatar {
  position: absolute;
  left: 13px;
  top: 15px;
  border-width: 3px;
  border-style: solid;
  z-index: 1;
  background-color: var(--finwallapp-theme-text);
}

.log-information li .avatar:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.log-information li p {
  margin: 0;
}

.log-information.bubble-sheet li p {
  padding: 10px 15px;
  position: relative;
  background-color: var(--finwallapp-card-color);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: calc(var(--finwallapp-rounded) - 5px);
  opacity: 1;
}

.log-information.bubble-sheet li p:before {
  content: "";
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  height: 10px;
  width: 10px;
  border-radius: 0px;
  position: absolute;
  left: -20px;
  top: 10px;
}

/* 15. footer */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0 0;
  background-color: var(--finwallapp-footer);
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
  -webkt-box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.07);
}

.footer .nav {
  align-items: center;
  max-width: 480px;
  margin: 0 auto;
}

.footer .nav .nav-item {
  height: 60px;
}

.footer .nav .nav-item .nav-link {
  text-align: center;
  background: transparent;
  align-self: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  height: 100%;
  line-height: 44px;
  color: var(--finwallapp-theme-text-secondary);
  padding: calc(var(--finwallapp-padding) - 5px) calc(var(--finwallapp-padding) - 10px);
}

.footer .nav .nav-item .nav-link span {
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.footer .nav .nav-item .nav-link span .nav-icon {
  font-size: 17px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 auto;
}

.footer .nav .nav-item .nav-link span .nav-text {
  font-size: 10px;
  line-height: 15px;
  display: block;
}

.footer .nav .nav-item .nav-link.active {
  color: var(--finwallapp-theme-color);
}

.footer .nav .nav-item.centerbutton {
  padding: 10px;
  transition: none;
}

.footer .nav .nav-item.centerbutton .nav-link {
  position: relative;
  padding: 8px;
  height: 76px;
  width: 76px;
  margin: 0 auto;
  margin-top: -30px;
  transition: none;
}

.footer .nav .nav-item.centerbutton .nav-link > span {
  height: 60px;
  line-height: 56px;
  width: 60px;
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: 0px auto 0 auto;
  color: var(--finwallapp-theme-text);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  -webkt-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}

.footer .nav .nav-item.centerbutton .nav-link > span i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  display: inline-block;
}

.footer .nav .nav-item.centerbutton .nav-link.active > span i {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.menu-open .footer {
  margin-bottom: -80px;
}

.footer-info {
  padding: 0px;
  line-height: 30px;
}

.chat-post {
  width: 100%;
  background-color: var(--finwallapp-card-color);
  padding: calc(var(--finwallapp-padding) - 5px) var(--finwallapp-padding);
  border-radius: var(--finwallapp-rounded) var(--finwallapp-rounded) 0 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);
  z-index: 2;
}

/* 16. footer */
.dark-mode {
  /* color schemes */
  --finwallapp-theme-color: #6500d8;
  --finwallapp-theme-text: #ffffff;
  --finwallapp-theme-text-primary: #e6def0;
  --finwallapp-theme-text-secondary: #d8bbff;
  --finwallapp-theme-text-secondary-light: #bbbbbb;
  --finwallapp-theme-bordercolor: rgba(255, 255, 255, 0.1);
  --finwallapp-header: transparent;
  --finwallapp-header-active: #290058;
  --finwallapp-footer: #290058;
  --finwallapp-sidebar: var(--finwallapp-theme-color);
  --finwallapp-card-color: rgba(64, 0, 138, 0.85);
  --finwallapp-page-bg-1: #290058;
  --finwallapp-page-bg-2: #1c003b;
  --finwallapp-page-bg-3: #090013;
  --finwallapp-page-text: #ffffff;
  --finwallapp-page-link: var(--finwallapp-theme-color);
}

.dark-mode .theme-indigo {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #8b9dff;
  --finwallapp-theme-color-grad-2: #5c00f1;
  --finwallapp-theme-color-grad-3: #68008e;
  /* color schemes */
  --finwallapp-theme-color: #6610f2;
  --finwallapp-header-active: #2d066d;
  --finwallapp-footer: #2d066d;
  --finwallapp-card-color: #2d066d;
  --finwallapp-page-bg-1: #210450;
  --finwallapp-page-bg-2: #11022a;
  --finwallapp-page-bg-3: #010003;
}

.dark-mode .theme-purple {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #e908d8;
  --finwallapp-theme-color-grad-2: #5a2ab3;
  --finwallapp-theme-color-grad-3: #300284;
  /* color schemes */
  --finwallapp-theme-color: #6f42c1;
  --finwallapp-header-active: #321d57;
  --finwallapp-footer: #321d57;
  --finwallapp-card-color: #321d57;
  --finwallapp-page-bg-1: #241540;
  --finwallapp-page-bg-2: #130b22;
  --finwallapp-page-bg-3: #020103;
}

.dark-mode .theme-pink {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ff71c3;
  --finwallapp-theme-color-grad-2: #e80075;
  --finwallapp-theme-color-grad-3: #4d00da;
  /* color schemes */
  --finwallapp-theme-color: #d63384;
  --finwallapp-header-active: #66143d;
  --finwallapp-footer: #66143d;
  --finwallapp-card-color: #66143d;
  --finwallapp-page-bg-1: #4c0f2e;
  --finwallapp-page-bg-2: #2a0919;
  --finwallapp-page-bg-3: #080205;
}

.dark-mode .theme-red {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffc699;
  --finwallapp-theme-color-grad-2: #ff5d83;
  --finwallapp-theme-color-grad-3: #dc006a;
  /* color schemes */
  --finwallapp-theme-color: #f73563;
  --finwallapp-header-active: #970628;
  --finwallapp-footer: #970628;
  --finwallapp-card-color: #970628;
  --finwallapp-page-bg-1: #7a0521;
  --finwallapp-page-bg-2: #530316;
  --finwallapp-page-bg-3: #2b020c;
}

.dark-mode .theme-orange {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffda6b;
  --finwallapp-theme-color-grad-2: #ff7c15;
  --finwallapp-theme-color-grad-3: #e80774;
  /* color schemes */
  --finwallapp-theme-color: #fd7e14;
  --finwallapp-header-active: #813b01;
  --finwallapp-footer: #813b01;
  --finwallapp-card-color: #813b01;
  --finwallapp-page-bg-1: #632d01;
  --finwallapp-page-bg-2: #3a1b00;
  --finwallapp-page-bg-3: #120800;
}

.dark-mode .theme-yellow {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #ffdb6f;
  --finwallapp-theme-color-grad-2: #f0ac00;
  --finwallapp-theme-color-grad-3: #f03000;
  /* color schemes */
  --finwallapp-theme-color: #ffbd17;
  --finwallapp-header-active: #876100;
  --finwallapp-footer: #876100;
  --finwallapp-card-color: #876100;
  --finwallapp-page-bg-1: #694b00;
  --finwallapp-page-bg-2: #402e00;
  --finwallapp-page-bg-3: #171000;
}

.dark-mode .theme-green {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #bdffaa;
  --finwallapp-theme-color-grad-2: #04c5de;
  --finwallapp-theme-color-grad-3: #00a0f5;
  /* color schemes */
  --finwallapp-theme-color: #00dfa3;
  --finwallapp-header-active: #00503b;
  --finwallapp-footer: #00503b;
  --finwallapp-card-color: #00503b;
  --finwallapp-page-bg-1: #003224;
  --finwallapp-page-bg-2: #000906;
  --finwallapp-page-bg-3: black;
}

.dark-mode .theme-teal {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #a2fffa;
  --finwallapp-theme-color-grad-2: #00a2ad;
  --finwallapp-theme-color-grad-3: #3f51b5;
  /* color schemes */
  --finwallapp-theme-color: #0ab2be;
  --finwallapp-header-active: #033336;
  --finwallapp-footer: #033336;
  --finwallapp-card-color: #033336;
  --finwallapp-page-bg-1: #011819;
  --finwallapp-page-bg-2: black;
  --finwallapp-page-bg-3: black;
}

.dark-mode .theme-cyan {
  /* radial gradient colors */
  --finwallapp-theme-color-grad-1: #74e5fb;
  --finwallapp-theme-color-grad-2: #258cd6;
  --finwallapp-theme-color-grad-3: #3d00ab;
  /* color schemes */
  --finwallapp-theme-color: #0dcaf0;
  --finwallapp-header-active: #065869;
  --finwallapp-footer: #065869;
  --finwallapp-card-color: #065869;
  --finwallapp-page-bg-1: #04404c;
  --finwallapp-page-bg-2: #021f25;
  --finwallapp-page-bg-3: black;
}

.dark-mode .alert-danger,
.dark-mode .card.alert-danger,
.dark-mode .bg-danger-light {
  background-color: rgba(247, 53, 99, 0.35);
}

.dark-mode .alert-info,
.dark-mode .card.alert-info,
.dark-mode .bg-info-light {
  background-color: rgba(10, 178, 190, 0.35);
}

.dark-mode .alert-primary,
.dark-mode .card.alert-primary,
.dark-mode .bg-primary-light {
  background-color: rgba(60, 99, 226, 0.35);
}

.dark-mode .alert-warning,
.dark-mode .card.alert-warning,
.dark-mode .bg-warning-light {
  background-color: rgba(255, 189, 23, 0.35);
}

.dark-mode .alert-success,
.dark-mode .card.alert-success,
.dark-mode .bg-success-light {
  background-color: rgba(0, 223, 163, 0.35);
}

.dark-mode .alert-theme,
.dark-mode .card.alert-theme,
.dark-mode .bg-theme-light {
  background-color: var(--finwallapp-theme-color-light);
}

.dark-mode main.bg-1 {
  background: url("../img/bg1-dark.png") fixed;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
}

.dark-mode main.bg-2 {
  background: url("../img/bg2-dark.png") fixed;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center right;
}

.dark-mode main.bg-3 {
  background: url("../img/bg3-dark.png") fixed;
  background-repeat: no-repeat;
  background-size: auto auto;
  background-position: 35% bottom;
}

.dark-mode .progressbar-text {
  color: var(--finwallapp-theme-text) !important;
}

.dark-mode .border, .dark-mode .border-top, .dark-mode .border-end, .dark-mode .border-bottom, .dark-mode .border-start {
  border-color: var(--finwallapp-theme-bordercolor) !important;
}

.dark-mode body,
.dark-mode .btn {
  color: var(--finwallapp-theme-text);
}

.dark-mode a:not(.btn):not(.list-group-item) {
  --finwallapp-theme-color: #638ef1;
}

.dark-mode a:not(.btn):not(.list-group-item):focus, .dark-mode a:not(.btn):not(.list-group-item):hover, .dark-mode a:not(.btn):not(.list-group-item):active {
  color: #ffffff;
}

.dark-mode .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--finwallapp-theme-text);
}

.dark-mode .tabs .nav-item > .nav-link:not(.active) {
  --finwallapp-theme-color: var(--finwallapp-theme-text);
}

.dark-mode .chartdoughnut > .datadisplay {
  --finwallapp-theme-text: var(--finwallapp-theme-color);
}

.dark-mode .btn-light {
  background-color: var(--finwallapp-page-bg-2);
  color: var(--finwallapp-theme-text);
  border-color: var(--finwallapp-page-bg-2);
}

.dark-mode .bg-light {
  background-color: var(--finwallapp-card-color) !important;
}

.dark-mode .btn-default:disabled {
  color: var(--finwallapp-theme-text);
}

.dark-mode .card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07), inset 0 1px 0px rgba(255, 255, 255, 0.1);
}

.dark-mode .accordion-item {
  background-color: transparent;
}

.dark-mode .accordion-item .accordion-header .accordion-button.collapsed {
  background-color: transparent;
  color: var(--finwallapp-theme-text);
}

.dark-mode .accordion-item .accordion-collapse.show {
  background-color: var(--finwallapp-card-color);
}

.dark-mode .list-group-item {
  color: var(--finwallapp-theme-text);
}

.dark-mode .bg-white {
  background-color: var(--finwallapp-card-color) !important;
}

.dark-mode .bg-opac-50 {
  background-color: var(--finwallapp-theme-color) !important;
}

.dark-mode .toast {
  background-color: var(--finwallapp-card-color);
}

.dark-mode .toast .toast-header {
  background-color: var(--finwallapp-theme-color);
  color: var(--finwallapp-card-text);
}

.dark-mode .modal-content {
  background-color: var(--finwallapp-theme-color);
}

.dark-mode .modal-content .modal-header {
  border-color: var(--finwallapp-theme-bordercolor);
}

.dark-mode .form-control,
.dark-mode input,
.dark-mode .dark-bg .form-control,
.dark-mode .dark-bg label {
  color: var(--finwallapp-input-text);
}

.dark-mode .form-control {
  background-color: var(--finwallapp-input-color);
  border-color: var(--finwallapp-input-bordercolor);
}

.dark-mode .chat-list .left-chat .chat-block {
  --finwallapp-theme-text: var(--finwallapp-page-text);
}

.dark-mode .daterange-center.daterangepicker {
  background-color: var(--finwallapp-page-bg-2);
}

.dark-mode .daterange-center.daterangepicker .calendar-table .next span,
.dark-mode .daterange-center.daterangepicker .calendar-table .prev span {
  border-color: var(--finwallapp-theme-text);
}

.dark-mode .daterange-center.daterangepicker td.off {
  background-color: var(--finwallapp-card-color) !important;
}

.dark-mode .daterange-center.daterangepicker td.in-range {
  color: var(--finwallapp-theme-text);
}

.dark-mode .text-purple,
.dark-mode .text-color-theme {
  color: var(--finwallapp-theme-text);
}

/* 17. Filter */
.filter {
  position: fixed;
  z-index: 999;
  width: 280px;
  right: -300px;
  top: 0;
  height: 100%;
  overflow-y: auto;
  padding: var(--finwallapp-padding);
}

.filter-open .filter {
  right: 0;
}

/* 18. Calendar */
.fc {
  border-radius: 5px;
  overflow: hidden;
}

.fc a {
  text-decoration: none;
}

.fc .fc-toolbar-title {
  font-size: 16px;
}

.fc .fc-button {
  padding: .275em .475em;
  font-size: 0.95em;
  line-height: 1.7;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
  padding: 10px 15px;
}

.fc .fc-toolbar.fc-header-toolbar .fc-button:focus {
  box-shadow: none;
  outline: none;
}

.fc.fc-theme-standard table {
  border: 0 none;
  border-collapse: collapse;
}

.fc.fc-theme-standard table th {
  font-size: 14px;
  text-decoration: none;
  padding: 10px 0;
  line-height: 20px;
  border: none;
}

.fc.fc-theme-standard table th a.fc-col-header-cell-cushion {
  font-weight: normal;
}

.fc.fc-theme-standard table td {
  border: none;
}

.fc.fc-theme-standard table table td {
  background-color: transparent;
  color: inherit;
  font-size: 14px;
  text-decoration: none;
  padding: 0;
  border: 1px solid rgba(190, 190, 190, 0.3);
}

.fc.fc-theme-standard table table td a {
  color: inherit;
  font-weight: normal;
}

.fc.fc-theme-standard table table td .fc-daygrid-more-link {
  font-size: 10px;
}

.fc.fc-theme-standard table table td .fc-daygrid-day-events {
  margin-top: -8px;
}

.fc.fc-theme-standard table table td:first-child {
  border-left: none;
}

.fc.fc-theme-standard table table td:last-child {
  border-right: none;
}

.fc.fc-theme-standard table table tr:first-child td {
  border-bottom: none;
}

.fc.fc-theme-standard table table tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 5px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }
}

/* date range picekr */
.calendar-daterange {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}

.daterange-center.daterangepicker {
  margin: 0 auto !important;
  right: 0 !important;
  left: 0 !important;
  top: 84px !important;
  width: 250px;
  border-radius: 10px;
  border: 0;
  background-color: var(--finwallapp-card-color);
}

.daterange-center.daterangepicker:before, .daterange-center.daterangepicker:after {
  display: none;
}

.daterange-center.daterangepicker .drp-buttons .drp-selected {
  display: none;
}

.daterange-center.daterangepicker .drp-buttons .cancelBtn {
  background: transparent;
  font-size: 14px;
}

.daterange-center.daterangepicker .drp-buttons .cancelBtn {
  color: var(--finwallapp-theme-color-light);
  background: transparent;
  font-size: 14px;
  font-weight: normal;
}

.daterange-center.daterangepicker .drp-buttons .applyBtn {
  background-color: var(--finwallapp-theme-color);
  color: var(--finwallapp-theme-text);
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 15px;
  font-weight: normal;
}

.daterange-center.daterangepicker .drp-calendar.left,
.daterange-center.daterangepicker .drp-calendar.right {
  padding: 10px;
  background: transparent;
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table {
  border: 0;
  background: transparent;
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td {
  line-height: 28px;
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off, .daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off.start-date, .daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off.end-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off.start-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off.end-date {
  background-color: var(--finwallapp-theme-text);
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.in-range, .daterange-center.daterangepicker .drp-calendar.left .calendar-table th.off.in-range,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.in-range,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.off.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.off.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.in-range,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.off.in-range {
  background-color: var(--finwallapp-theme-bordercolor);
}

.daterange-center.daterangepicker .drp-calendar.left .calendar-table th.active, .daterange-center.daterangepicker .drp-calendar.left .calendar-table th.active:hover, .daterange-center.daterangepicker .drp-calendar.left .calendar-table th.in-range.start-date, .daterange-center.daterangepicker .drp-calendar.left .calendar-table th.in-range.end-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.active,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.active:hover,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.left .calendar-table td.in-range.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.active,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.active:hover,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table th.in-range.end-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.active,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.active:hover,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.in-range.start-date,
.daterange-center.daterangepicker .drp-calendar.right .calendar-table td.in-range.end-date {
  background-color: var(--finwallapp-theme-color);
}

/* 19. Logo */
.logo-splash {
  width: auto;
  display: block;
  margin: 0 auto;
}

.logo-splash img {
  width: 100px;
  margin-bottom: 15px;
  border-radius: 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}

.logo-small {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  line-height: 44px;
}

.logo-small img {
  width: 40px;
  margin: 0;
  border-radius: calc(var(--finwallapp-rounded) - 8px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  margin-right: 8px;
  border: 0;
  vertical-align: middle;
}

.logo-small h5, .logo-small .h5 {
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
}

.logo-wallet {
  height: 100px;
  width: 100px;
  border-radius: 25px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.logo-wallet .wallet-bottom {
  height: 100px;
  width: 100px;
  position: relative;
  border-radius: 25px;
  background-color: var(--finwallapp-theme-color);
}

.logo-wallet .wallet-cards {
  height: 80px;
  width: 50px;
  top: 18px;
  left: 0px;
  position: absolute;
  border-radius: 15px;
  transform: rotate(0deg);
  background-color: #f73563;
  z-index: 3;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  animation: rotateanimation ease 2s infinite;
}

.logo-wallet .wallet-cards:after {
  content: "";
  position: absolute;
  border-radius: 15px;
  height: 80px;
  width: 50px;
  left: -5px;
  background-color: #ffbd17;
  transform: rotate(-15deg);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  z-index: -1;
}

.logo-wallet .wallet-cards::before {
  content: "";
  position: absolute;
  border-radius: 15px;
  left: -10px;
  height: 80px;
  width: 50px;
  transform: rotate(-30deg);
  background-color: #00dfa3;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  z-index: 3;
}

.logo-wallet .wallet-top {
  height: 100px;
  width: 65px;
  border-radius: 25px;
  top: 0;
  left: 0;
  position: absolute;
  background: var(--finwallapp-theme-color-grad-1);
  background: -moz-radial-gradient(30% 30%, ellipse cover, var(--finwallapp-theme-color-grad-1) 0%, var(--finwallapp-theme-color-grad-2) 50%, var(--finwallapp-theme-color-grad-3) 100%);
  background: -webkit-radial-gradient(30% 30%, ellipse cover, var(--finwallapp-theme-color-grad-1) 0%, var(--finwallapp-theme-color-grad-2) 50%, var(--finwallapp-theme-color-grad-3) 100%);
  background: radial-gradient(ellipse at 30% 30%, var(--finwallapp-theme-color-grad-1) 0%, var(--finwallapp-theme-color-grad-2) 50%, var(--finwallapp-theme-color-grad-3) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--finwallapp-theme-color-grad-1)', endColorstr='var(--finwallapp-theme-color-grad-3)',GradientType=1 );
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  z-index: 5;
  animation: widthtopanimation infinite 2s ease;
}

@keyframes rotateanimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
    left: 28px;
  }
  80% {
    transform: rotate(45deg);
    left: 28px;
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes widthtopanimation {
  0% {
    width: 100%;
  }
  20% {
    width: 65%;
  }
  85% {
    width: 65%;
  }
  100% {
    width: 100%;
  }
}

/* 20. tabs layout */
.tabs {
  background-color: var(--finwallapp-theme-bordercolor);
  border-radius: var(--finwallapp-rounded);
  padding: 5px;
}

.tabs .nav-item > .nav-link {
  color: var(--finwallapp-theme-color);
  padding: calc(var(--finwallapp-padding) - 5px) var(--finwallapp-padding);
  line-height: 30px;
  border-radius: calc(var(--finwallapp-rounded) - 5px);
  text-transform: uppercase;
}

.tabs .nav-item > .nav-link.active {
  color: var(--finwallapp-theme-text);
  background-color: var(--finwallapp-theme-color);
}

/* 21. tags */
/* tags */
.tag {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 14px;
  padding: 0 var(--finwallapp-padding);
  background-color: var(--finwallapp-card-color);
  color: var(--finwallapp-theme-text-secondary-light);
  border-radius: var(--finwallapp-rounded);
  display: inline-block;
}

.tag > i {
  margin-right: 5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}

.tag.active {
  color: var(--finwallapp-theme-text-primary);
  border-color: var(--finwallapp-theme-text-primary) !important;
}

.tag.tag-small {
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  font-size: 12px;
}

.tag.tag-large {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

/* 22. Tahank you */
/* thank you*/
.thankyouimg {
  overflow: hidden;
}

.thankyouimg .thankyoubg {
  right: 0px;
  position: relative;
}

.thankyouimg .slideimg {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
}

.thankyouimg .text {
  font-size: 84px;
  opacity: 0.2;
  color: var(--finwallapp-theme-text);
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  top: 46%;
  right: 0;
}

.thankyouimg .circle.one {
  left: 8%;
  top: 32%;
}

.thankyouimg .circle.two {
  right: 0%;
  top: 48%;
  z-index: 1;
}

.thankyouimg .circle.three {
  left: 36%;
  top: 82%;
}

.thankyouimg2 {
  overflow: hidden;
}

.thankyouimg2 .thankyoubg {
  left: -110px;
  position: relative;
}

.thankyouimg2 .bg404bg {
  display: block;
  max-width: 96%;
  margin: 0 auto;
}

.thankyouimg2 .slideimg {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
}

.thankyouimg2 .slideimg404 {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0%;
}

.thankyouimg2 .text {
  font-size: 84px;
  opacity: 0.2;
  color: var(--finwallapp-theme-text);
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  top: 6%;
  right: 0;
}

.thankyouimg2 .circle.one {
  left: 75%;
  top: 7%;
}

.thankyouimg2 .circle.two {
  right: 5%;
  top: 62%;
  z-index: 1;
}

.thankyouimg2 .circle.three {
  left: 26%;
  top: 82%;
}

@media screen and (max-width: 340px) {
  .thankyouimg .thankyoubg {
    max-height: 200px;
  }
  .thankyouimg .slideimg {
    max-height: 240px;
  }
  .thankyouimg .circle.three {
    left: 42%;
    top: 75%;
  }
  .thankyouimg2 .thankyoubg {
    max-height: 200px;
  }
  .thankyouimg2 .slideimg {
    max-height: 110px;
  }
  .thankyouimg2 .circle.one {
    left: 14%;
    top: 3%;
  }
  .thankyouimg2 .circle.two {
    left: 70%;
    top: 47%;
  }
  .thankyouimg2 .circle.three {
    left: 32%;
    top: 75%;
  }
}

/* 23. RTL layout */
.rtl {
  direction: rtl;
}

.rtl .tooltip-btn {
  left: 5px;
  right: auto;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .dropdown-menu {
  text-align: right;
}

.rtl .toast-header .btn-close {
  margin-left: -.375rem;
  margin-right: .75rem;
}

.rtl .me-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ps-0 {
  padding-left: var(--finwallapp-padding) !important;
  padding-right: 0;
}

.rtl .pe-0 {
  padding-right: var(--finwallapp-padding) !important;
  padding-left: 0;
}

.rtl .float-end {
  float: left !important;
}

.rtl .start-auto {
  left: 0 !important;
  right: auto !important;
}

.rtl .text-end {
  text-align: left !important;
}

.rtl .form-floating > label {
  left: auto;
  right: 0;
}

.rtl .sidebar-wrap {
  left: auto;
  right: -100%;
}

.rtl .sidebar-wrap .closemenu {
  right: auto;
  left: -25px;
}

.rtl .sidebar-wrap .sidebar {
  float: right;
}

.rtl .sidebar-wrap .sidebar .nav .nav-item .nav-link > .icon {
  margin-left: 15px;
  margin-right: 0;
}

.rtl .sidebar-wrap.sidebar-overlay .sidebar {
  left: auto;
  right: 0;
}

.rtl .sidebar-wrap.sidebar-fullmenu .closemenu {
  left: 15px;
  right: auto;
}

.rtl .sidebar-wrap.sidebar-fullmenu .nav .nav-item .nav-link > .icon {
  margin-left: auto;
  margin-right: auto;
}

.rtl.menu-open .sidebar-wrap {
  right: 0;
}

.rtl.menu-open .sidebar-wrap.sidebar-pushcontent + main {
  margin-left: auto;
  transform: scale(1);
  margin-right: 280px;
}

.rtl .summayswiper .swiper-wrapper .swiper-slide:first-child,
.rtl .cardswiper .swiper-wrapper .swiper-slide:first-child,
.rtl .connectionwiper .swiper-wrapper .swiper-slide:first-child,
.rtl .summayswiper .swiper-wrapper .swiper-slide:first-child {
  padding-left: 5px;
  padding-right: var(--finwallapp-padding);
}

.rtl .summayswiper .swiper-wrapper .swiper-slide:last-child,
.rtl .cardswiper .swiper-wrapper .swiper-slide:last-child,
.rtl .connectionwiper .swiper-wrapper .swiper-slide:last-child,
.rtl .summayswiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: 5px;
  padding-left: var(--finwallapp-padding);
}

.rtl ol, .rtl ul {
  padding-right: 0;
}

.ellipsify {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Default width for larger screens */
}
.mbshow{
  display: none;
}

@media (max-width: 768px) { /* For mobile screens */
  .ellipsify {
    width: 10ch; /* Display only the first 5 characters */
  }
  .mbhide{
    display: none;
  }
  .mbshow{
    display: block;
  }
}
