.list-group-item {
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s ease;
  }
  
  .list-group-item.active {
    background-color: #f0f0f0; 
  }
  
  .details {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    padding: 0;
    margin-top: 0;
    color: black;
  }
  
  .details.open {
    max-height: 300px; /* Adjust based on the expected content height */
    opacity: 1;
    padding: 15px 0;
    margin-top: 15px;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  