.swap-page {
    /* height: 100vh; */
    /* background-color: #f0f2f5; */
  }
  
  .swap-box {
    width: 100%;
    max-width: 400px;
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .swap-box h2 {
    font-weight: bold;
    color: #333;
  }
  
  .logo-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .dropdown-toggle {
    display: flex;
    align-items: center;
    /* background-color: #f8f9fa !important; */
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 45px;
    font-weight: bold;
    width: 100%;
  }
  
  .dropdown-menu {
    width: 100%;
    margin-top: 0;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .form-control {
    border-radius: 5px;
    height: 45px;
    /* margin-top: 8px; */
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 5px;
    height: 45px;
    font-weight: bold;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .input-with-icon .form-control {
    border-right: none;
    border-radius: 0.25rem 0 0 0.25rem;
    padding-right: 0;
  }
  
  .input-with-icon .input-group-text {
    background-color: #f8f9fa;
    border-left: none;
    border-radius: 0 0.25rem 0.25rem 0;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
  
  .input-group-text svg {
    width: 20px;
    height: 20px;
  }
  
  .text-muted {
    font-size: 0.9rem;
    margin-top: 5px;
    display: block;
  }